import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  state: {
    customerUser: {
    },
  },
  getters: {
    getCustomerUser(state) {
      return state.customerUser
    },
  },
  namespaced: true,
  mutations: {},
  actions: {
    getCustomerUser(_, {
      claveOMNI,
      orderBy = null,
      perPage,
      currentPage,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_user_customer?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByClaveOMNI: claveOMNI,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAllCustomerUser() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('customers/get_user_customer', {
            params: {
              claveOMNI: '',
            },
            timeout: 4000,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
