import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {
    productData: {
      productID: {
        display: false,
        value: 0,
        type: 'input',
      },
      productCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'product',
      },
      productName: {
        display: true,
        value: '',
        type: 'input',
        label: 'description',
      },
      productCost: {
        display: true,
        value: 0,
        type: 'numInput',
        label: 'USDcost',
      },
      webDescription: {
        display: true,
        value: '',
        type: 'input',
        label: 'webDescription',
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      isWeb: {
        display: true,
        value: false,
        type: 'checkbox',
        label: 'web',
      },
    },
  },
  getters: {
    productData(state) {
      return state.productData
    },
  },
  mutations: {},
  actions: {
    fetchProducts(
      _,
      {
        filterByProductCode, orderBy = null, perPage, currentPage,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/products?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: true,
              FilterByProductCode: filterByProductCode,
            }, // OrderBy[var]=ASC || DESC <-- Formato correcto
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveProducts(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/products', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchProductsById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/products/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateProducts(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/products/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
