export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/ui/page-layouts/LayoutWidgets.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clientes',
    name: 'clients',
    component: () => import('@/views/clients/ClientsList.vue'),
    meta: {},
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      pageTitle: 'Second Page',
      breadcrumb: [
        {
          text: 'Second Page',
          active: true,
        },
      ],
    },
  },
  {
    path: '/widgets',
    name: 'widgets',
    component: () => import('@/views/ui/page-layouts/LayoutWidgets.vue'),
    meta: {
    },
  },
  // *===============================================---*
  // *--------- USER -----------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
  },
  {
    path: '/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/user/users-view/UsersView.vue'),
  },
  {
    path: '/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
  },
  // *===============================================---*
  // *--------- PROFILE --------------------------------*
  // *===============================================---*
  {
    path: '/profile',
    name: 'pages-profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
]
