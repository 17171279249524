import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

const countries = []

export default {
  state: {
    holidayData: {
      holidayId: {
        display: false,
        value: 0,
        type: 'input',
      },
      holidayName: {
        display: true,
        value: '',
        type: 'input',
        label: 'description',
      },
      holidayDate: {
        display: true,
        value: '',
        type: 'datePicker',
        label: 'date',
      },
      countryId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'country',
        options: countries,
      },
      isRepeatedAnually: {
        display: true,
        value: false,
        type: 'checkbox',
        label: 'IsRepeatedAnually',
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      version: {
        display: false,
        value: '',
        type: '',
      },
    },
  },
  getters: {
    getInitHolidays(state) {
      return state.holidayData
    },
  },
  namespaced: true,
  mutations: {},
  actions: {
    async fetchCountries({ rootGetters }, { holidayData = {} }) {
      const countryOptions = await rootGetters['init/countries'].map(country => ({
        value: country.countryID,
        text: country.countryName,
      }))
      holidayData.value.countryId.options = countryOptions
    },
    getHolidays(
      _,
      {
        perPage, currentPage, orderBy = null,
      },
    ) {
      if (orderBy) {
        orderBy = Object.entries(orderBy).reduce((acc, [key, value]) => {
          acc[key.split('.')[1]] = value
          return acc
        }, {})
      }
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(
            `/holidays?${Object.entries(orderBy)
              .map(([clave, valor]) => `OrderBy[${clave}]=${valor}`)
              .join('&')}`,
            {
              params: {
                PerPage: perPage,
                CurrentPage: currentPage,
                OrderBy: orderBy,
              },
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchHolidayById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/holidays/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveHoliday(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/holidays', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateHoliday(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/holidays', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
