import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    clasificationTypes: {
      spidClasifTransTypeId: {
        display: false,
        value: 0,
      },
      spidClasifTransTypeCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'code',
      },
      spidClasifTransTypeName: {
        display: true,
        value: '',
        type: 'input',
        label: 'description',
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      version: {
        display: false,
        value: '',
      },
    },
  },
  getters: {
    clasificationTypes(state) {
      return state.clasificationTypes
    },
  },
  mutations: {},
  actions: {
    getClasificationTypes(
      _,
      {
        perPage, currentPage, filterByProductCode, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/spid_clasification_types', {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              filterByIsActive: null,
              FilterByProductCode: filterByProductCode,
              OrderBy: orderBy,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchClasificationTypeById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/spid_clasification_types/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveClasificationType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/spid_clasification_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateClasificationType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/spid_clasification_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
