import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { getEnvVariable } from '@/helpers'

const getLocalHome = state => {
  if (state.home) return state.home
  const home = localStorage.getItem('home')
  if (home) {
    return JSON.parse(home)
  }
  return {}
}

export default {
  namespaced: true,
  state: {
    openDay: null,
    currencies: null,
    home: null,
    currencyPairs: null,
  },
  getters: {
    openDay(state) {
      if (state.openDay) return state.openDay
      const openDay = sessionStorage.getItem('openDay')
      if (openDay) {
        return JSON.parse(openDay)
      }
      return null
    },

    home(state) {
      return getLocalHome(state)
    },
  },
  mutations: {
    SET_OPEN_DAY(state, payload) {
      state.openDay = payload
      sessionStorage.setItem('openDay', JSON.stringify(payload))
    },
    SET_CURRENCIES(state, payload) {
      state.currencies = payload
      sessionStorage.setItem('currencies', JSON.stringify(payload))
    },
    SET_CURRENCY_PAIRS(state, payload) {
      state.currencyPairs = payload
      sessionStorage.setItem('currencyPairs', JSON.stringify(payload))
    },
    SET_HOME(state, payload) {
      state.home = payload
      localStorage.setItem('home', JSON.stringify(payload))
      const now = new Date()
      const cacheDays = getEnvVariable('HOME_CACHE_DAYS', process.env.HOME_CACHE_DAYS) || 1
      const ttl = now.getTime() + (cacheDays * 24 * 60 * 60 * 1000) // set TTL to 24 hours from now
      localStorage.setItem('homeTTL', ttl)
    },
    LOAD_HOME(state) {
      state.home = getLocalHome(state)
    },
  },
  actions: {
    getCurrencyName(context, currencyId) {
      const currency = context.getters.currencies.find(e => e.id === currencyId)
      if (currency) {
        return currency.name
      }
      return ''
    },
    getOpenDay(context) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('v1/open_day')
          .then(response => {
            context.commit('SET_OPEN_DAY', response.data)
            resolve(response.data)
          })
          .catch(error => {
            context.commit('SET_OPEN_DAY', '{ "isActive": false }')
            reject(error)
          })
      })
    },

    getHome(context) {
      return new Promise((resolve, reject) => {
        const { home } = context.getters
        const now = Date.now()
        const homeTTL = localStorage.getItem('homeTTL') || now - (1 * 60 * 1000)
        if (home === undefined || home === null || Object.entries(home).length === 0 || homeTTL <= now) {
          sigloNetAxiosInstance
            .get('v1/home')
            .then(response => {
              context.commit('SET_HOME', response.data)
              resolve(response.data)
            })
            .catch(error => {
              context.commit('SET_HOME', {})
              reject(error)
            })
        } else {
          context.commit('LOAD_HOME')
          resolve(context.state.home)
        }
      })
    },

    fetchCurrencyPairs({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (sessionStorage.getItem('currencyPairs')) {
          resolve(getters.currencyPairs)
          return
        }
        sigloNetAxiosInstance
          .get('/currency-pairs?Orderby[FilterByCurrencyId]=DESC', {
            params: {
              FilterByIsActive: true,
              PerPage: 100,
            },
          })
          .then(response => {
            commit('SET_CURRENCY_PAIRS', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchStatesByCountry(context, { CountryId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/states/get-by-country', {
            params: {
              CountryId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCitiesByState(context, { StateId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/cities/get_by_state', {
            params: {
              StateId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCurrencyPairsById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/currency-pairs/get_by_currency_id', {
            params: {
              id,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchCurrencyPair(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/currency-pairs', {
            params: {
              FilterByIsActive: true,
              PerPage: 100,
              FilterByCurrencyId: id,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCurrencyPairDetails(_, { currencyPairId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/currency-pairs/${currencyPairId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
