import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  state: {
    dayParam: {
      dayId: {
        display: false,
        value: '0',
        type: 'input',
      },
      termTodayDate: {
        display: false,
        value: '',
        type: 'datePicker',
        label: 'termTodayDate',
      },
      isDayStarted: {
        display: true,
        value: '',
        type: 'select',
        label: 'startDay',
      },
      isDayFinished: {
        display: true,
        value: '',
        type: 'select',
        label: 'endDayBoard',
      },
      term24HDate: {
        display: false,
        value: '',
        type: 'datePicker',
        label: 'term24HDate',
      },
      term48HDate: {
        display: false,
        value: '',
        type: 'datePicker',
        label: 'term48HDate',
      },
      term72HDate: {
        display: false,
        value: '',
        type: 'datePicker',
        label: 'term72HDate',
      },
      term96HDate: {
        display: false,
        value: '',
        type: 'datePicker',
        label: 'term96HDate',
      },
      termForwardDate: {
        display: false,
        value: '',
        type: 'datePicker',
        label: 'termForwardDate',
      },
      isEndCompleteOp: {
        display: true,
        value: '',
        type: 'select',
        label: 'EndCompleteOp',
      },
      isEndWebCapture: {
        display: true,
        value: '',
        type: 'select',
        label: 'EndWebCapture',
      },
      isEndCaptureOp: {
        display: true,
        value: '',
        type: 'select',
        label: 'EndCaptureOp',
      },
    },
  },
  getters: {
    getInitialParams(state) {
      return state.dayParam
    },
  },
  namespaced: true,
  mutations: {},
  actions: {
    getDayParams() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day/index')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDayParamsById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/open_day/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateDayParams(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/open_day/${data.id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
