import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

const currencyPairs = []
const products = []
const values = []

export default {
  namespaced: true,
  state: {
    webPrices: {
      webPriceId: {
        display: false,
        value: 0,
        type: 'input',
        label: 'webPrice',
      },
      currencyPairId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'currencyPair',
        options: currencyPairs,
      },
      productId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'products',
        options: products,
      },
      valueDateId: {
        display: true,
        value: 0,
        type: 'select',
        label: 'spotType',
        options: values,
      },
      version: {
        display: false,
        value: '',
        type: '',
      },
    },
  },

  getters: {
    webPrices(state) {
      return state.webPrices
    },
  },
  mutations: {},
  actions: {
    getWebPrices() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/web_price')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteWebPrice(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .delete(`/web_price/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateWebPrice(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/web_price/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveWebPrice(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/web_price', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async fetchProducts({ rootGetters }, { webPrices = {} }) {
      const countryOptions = await rootGetters['init/products'].map(product => ({
        value: product.id,
        text: product.name,
      }))
      webPrices.value.productId.options = countryOptions
    },
    async fetchCurrencies({ rootGetters }, { webPrices = {} }) {
      const countryOptions = JSON.parse(sessionStorage.getItem('currencyPairs')).results.map(currency => ({
        value: currency.currencyPairId,
        text: currency.currencyPairISOCode,
      }))
      webPrices.value.currencyPairId.options = countryOptions
    },
    async fetchValueDate({ rootGetters }, { webPrices = {} }) {
      const countryOptions = await rootGetters['openDay/getTerms'].map(date => ({
        value: date.term,
        text: date.termDisplayLabel,
      }))
      webPrices.value.valueDateId.options = countryOptions
    },
  },
}
