import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    saveOrder(_, { IsWeakCurrency = false, order }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(`/orders/${IsWeakCurrency}`, order)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOrderStatus(_, { hasOptionAll, hasOptionPending }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/order_status', {
            params: {
              hasOptionAll,
              hasOptionPending,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOrders(_, {
      portfolioId, traderId, statusCode, startDate, endDate,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/orders', {
            params: {
              portfolioId, traderId, statusCode, startDate, endDate,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOrder(_, { orderId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/orders/${orderId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateOrder(_, { order }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/orders/update', order)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelOrder(_, { orderId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(`/orders/cancel/${orderId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
