import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPaymentMethod(
      _,
      {
        perPage, currentPage, filterByIsActive = null, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/payment_method?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: filterByIsActive,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getPaymentMethodById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/payment_method/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    savePaymentMethod(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/payment_method', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updatePaymentMethod(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/payment_method/${id}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
