// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default function widgetFunctions() {
  const currentBreakPoint = store.getters['app/currentBreakPoint']

  // Remove widget in the active board
  function addWidget(data) {
    // Open one Widget by board only for mobil
    if (currentBreakPoint === 'xs' || currentBreakPoint === 'sm') {
      store.commit('boards/ADD_BOARD')
      store.commit('boards/SELECT_BOARD', store.state.boards.boards.length - 1)
    }

    let boardIndex
    let boardId
    if (data.board) {
      boardIndex = data.boardIndex
      boardId = data.board
    } else {
      boardIndex = store.state.boards.boards.findIndex(
        board => board.isActive,
      )
      boardId = store.state.boards.boards.find(objeto => objeto.isActive === true)?.id
    }

    store.commit('boards/ADD_WIDGET', {
      ...data,
      boardIndex,
      boardId,
    })
  }

  // Remove widget in the active board
  function removeWidget(widgetIndex) {
    const boardIndex = store.state.boards.boards.findIndex(
      board => board.isActive,
    )
    store.commit('boards/REMOVE_WIDGET', {
      boardIndex,
      widgetIndex,
    })

    // Remove board in mobile version
    if (currentBreakPoint === 'xs' || currentBreakPoint === 'sm') {
      store.commit('boards/REMOVE_BOARD', boardIndex)
    }
  }

  function restoreWidgets(widget) {
    addWidget({
      ...widget,
      boardIndex: widget.boardIndex,
      board: widget.board,
      focus: false,
      height: widget.h,
      width: widget.w,
    })
  }

  function restoreBoards() {
    const boards = JSON.parse(sessionStorage.getItem('storedBoards')) ?? []
    boards.forEach(board => {
      if (board.widgets !== undefined && board.widgets.length !== 0) {
        store.commit('boards/ADD_BOARD', board.id)
        const { widgets } = board
        widgets.forEach(widget => {
          restoreWidgets(widget)
        })
      }
    })
  }

  const elementsIds = () => {
    const elements = []
    const allElements = document.querySelectorAll('*')
    allElements.forEach(e => {
      if (e.id && e.id.startsWith('id-')) {
        elements.push(e.id)
      }
    })
    return elements
  }

  const scrollElement = e => {
    e.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const animation = e => {
    e.classList.add('border-primary')
    e.classList.add('rounded')
    setTimeout(() => {
      e.classList.remove('border-primary')
      e.classList.remove('rounded')
    }, 1000)
  }

  const verifyWidget = (idValue, widgetParams) => {
    const elements = elementsIds()
    if (elements.includes(`id-${idValue}`)) {
      const el = document.getElementById(`id-${idValue}`)
      scrollElement(el)
      setTimeout(() => {
        animation(el)
      }, 500)
    } else {
      addWidget({
        ...widgetParams,
      })
    }
  }

  // Returns price with the important decimals to be displayed bigger
  function scisaPrice(price, template = 'XX.##xxxx') {
    price = price.toString()
    const [, templateDecimals] = template.split('.')
    let [, decimals] = price.split('.')
    const [number] = price.split('.')
    if (!decimals) {
      decimals = '000000'
    }
    const formattedNumber = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const formatedDecimals = []
    for (let i = 0; i < templateDecimals.length; i += 1) {
      if (templateDecimals[i] === '#') formatedDecimals.push({ number: decimals[i], isImportant: true })
      else formatedDecimals.push({ number: decimals[i], isImportant: false })
    }
    // returns two args, first one is the natural number, second one is an array of objects with each decimal of the template
    return { formattedNumber, formatedDecimals }
  }

  return {
    addWidget,
    removeWidget,
    restoreWidgets,
    restoreBoards,

    scisaPrice,

    elementsIds,
    scrollElement,
    animation,
    verifyWidget,
  }
}
