import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInstitutionsData(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/institutions/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateInstitutionsData(_, { id, payload }) {
      const formData = new FormData()
      formData.append('Id', payload.id)
      formData.append('Name', payload.name)
      formData.append('RFC', payload.rfc)
      formData.append('WebPage', payload.webPage)
      formData.append('Logo', payload.log)
      formData.append('NetCapital', payload.netCapital)
      formData.append('MaxShortPct', payload.maxShortPct)
      formData.append('MaxLostPct', payload.maxLostPct)
      formData.append('Responsible', payload.responsible)
      formData.append('EmailResponsible', payload.emailResponsible)
      formData.append('BanxicoCode', payload.banxicoCode)
      formData.append('IsActive', payload.isActive)
      formData.append('Reference', payload.reference)
      formData.append('ResponsableMexBank', payload.responsebleMexBank)
      formData.append('MinimumCapital', payload.minimumCapital)
      formData.append('BaseCapital', payload.baseCapital)
      formData.append('Version', payload.version)
      formData.append('ContractId', payload.contractId)
      formData.append('CurrencyCommission', payload.currencyCommission)
      formData.append('CostCommission', payload.costCommission)
      formData.append('TAXCommission', payload.taxCommission)
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/institutions/${id}`, formData, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
