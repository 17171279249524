import Vue from 'vue'
import moment from 'moment'
import VueI18n from '@/libs/i18n/index'

Vue.filter('money', (value, decimals = 2) => {
  if (typeof value === 'undefined') {
    return 'undefined'
  }
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  })
  const hasDecimals = value.toString().split('.').length === 2
  if (hasDecimals) {
    value = `${value.toString().split('.')[0]}.${value
      .toString()
      .split('.')[1]
      .slice(0, decimals)}`
  }
  const val = currencyFormatter.format(value)
  return val
})

Vue.filter('moneyFormat', (value, decimals = 2) => {
  if (typeof value === 'undefined') {
    return 'undefined'
  }
  const currencyFormatter = new Intl.NumberFormat({
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  })
  const hasDecimals = value.toString().split('.').length === 2
  if (hasDecimals) {
    value = `${value.toString().split('.')[0]}.${value
      .toString()
      .split('.')[1]
      .slice(0, decimals)}`
  }
  const val = currencyFormatter.format(value)
  return val
})

Vue.filter('date', (value, plus) => moment(value).add(plus, 'days').format('DD/MMM/YYYY'))
Vue.filter('hour', (_, hours, minutes, seconds) => {
  const formattedHour = `${hours > 9 ? hours : `0${hours}`}:${
    minutes > 9 ? minutes : `0${minutes}`
  }`
  if (seconds) {
    formattedHour.concat(`:${seconds > 9 ? seconds : `0${seconds}`}`)
  }
  return formattedHour
})

Vue.filter('dateAndHour', value => moment(value).format('DD/MMM/YYYY HH:mm:ss'))

Vue.filter('decimals', (value, decimals = 2) => {
  if (typeof value === 'undefined') {
    return 'undefined'
  }
  const formattedNumber = value.toFixed(decimals)
  return formattedNumber
})

Vue.filter('yesNoFilter', value => { 
  if (value) {
    return VueI18n.t('yes')
  } else {
    return VueI18n.t('no')
  }
})
