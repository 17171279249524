import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

// Routes
import pages from './routes/pages'
import dashboard from './routes/dashboard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  try {
    const isLoggedIn = isUserLoggedIn()
    if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
      next('/login')
    } else if (to.matched.some(record => record.meta.guest) && isLoggedIn) {
      next('/')
    } else {
      next()
    }
  } catch {
    //
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
