import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAssociateOperationsIndex(
      _,
      {
        perPage, currentPage, filterBySwapId = null, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/associate_swap_operations/index?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterBySwapId: filterBySwapId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getAssociateOperations(_, { portfolioId, transForexId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/associate_swap_operations', {
            params: {
              portfolioId,
              transForexId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveAssociateOperation(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/associate_swap_operations', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteAssociateOperation(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .delete(`/associate_swap_operations/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
