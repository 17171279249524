import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    currencyPair: {
      currencyPairId: {
        display: false,
        value: 0,
        type: 'input',
      },
    },
  },
  getters: {
    currencyPair(state) {
      return state.currencyPair
    },
  },
  mutations: {},
  actions: {
    getPositionsContracted() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/global_position_contracted')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
