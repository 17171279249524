import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

const currencies = []
const significantDigitTypeMaskOptions = []

const setCurrencyNames = async (item, dispatch) => {
  const weakCurrencyName = await dispatch(
    'init/getCurrencyName',
    item.weakCurrencyId,
    { root: true },
  )
  const strongCurrencyName = await dispatch(
    'init/getCurrencyName',
    item.strongCurrencyId,
    { root: true },
  )
  item.weakCurrencyName = weakCurrencyName
  item.strongCurrencyName = strongCurrencyName
}

export default {
  namespaced: true,
  state: {
    currencyPair: {
      currencyPairId: {
        display: false,
        value: 0,
        type: 'input',
      },
      strongCurrencyId: {
        display: true,
        value: '',
        type: 'select',
        label: 'strongCurrency',
        options: currencies,
      },
      weakCurrencyId: {
        display: true,
        value: '',
        type: 'select',
        label: 'weakCurrency',
        options: currencies,
      },
      significantDigitTypeId: {
        display: true,
        value: '',
        type: 'select',
        label: 'mostSignificantDigit',
        options: significantDigitTypeMaskOptions,
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      isWeb: {
        display: true,
        value: false,
        type: 'checkbox',
        label: 'web',
      },
      isWeakCurrencyQuote: {
        display: true,
        value: false,
        type: 'checkbox',
        label: 'tradeWeakCurrency',
      },
      significantDigitTypeMask: {
        display: false,
        value: '',
        type: 'input',
        label: '',
      },
      version: {
        display: false,
        value: '',
        type: '',
        label: '',
      },
      weakCurrencyName: {
        display: false,
        value: '',
        type: '',
        label: '',
      },
      strongCurrencyName: {
        display: false,
        value: '',
        type: '',
        label: '',
      },
    },
  },
  getters: {
    currencyPair(state) {
      return state.currencyPair
    },
  },
  mutations: {},
  actions: {
    async fetchOptionsForCurrencyForm({ rootGetters }, { currencyPair = {} }) {
      const currenciesOptions = await rootGetters['init/currencies'].map(
        item => ({
          value: item.id,
          text: item.name,
        }),
      )
      const significantDigitTypeMaskOptions = await rootGetters[
        'init/currencyPairSignificantDigit'
      ]
      currencyPair.value.significantDigitTypeId.options = significantDigitTypeMaskOptions
      currencyPair.value.weakCurrencyId.options = currenciesOptions
      currencyPair.value.strongCurrencyId.options = currenciesOptions
    },
    async fetchCurrencyPairs(
      { dispatch },
      { orderBy = null, perPage, currentPage },
    ) {
      try {
        const response = await sigloNetAxiosInstance.get(`/currency-pairs?${sortRemote(orderBy)}`, {
          params: {
            PerPage: perPage,
            CurrentPage: currentPage,
          },
        })
        for (let i = 0; i < response.data.results.length; i++) {
          await setCurrencyNames(response.data.results[i], dispatch)
        }
        return response.data
      } catch (error) {
        throw error
      }
    },

    saveCurrencyPairs(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/currency-pairs', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    async fetchCurrencyPairsById({ dispatch }, { id, currencyPairRef }) {
      const response = await sigloNetAxiosInstance.get(`/currency-pairs/${id}`)
      await setCurrencyNames(response.data, dispatch)

      for (const key in currencyPairRef.value) {
        if (response.data.hasOwnProperty(key)) {
          currencyPairRef.value[key].value = response.data[key]
        }
      }
      return response.data
    },
  },
}
