// Axios
/* eslint-disable no-param-reassign */
import axios from 'axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line
import { getEnvVariable } from '@/helpers'

const Identity = axios.create({
  baseURL: getEnvVariable('VUE_APP_IDENTITY_URL', process.env.VUE_APP_IDENTITY_URL),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: getEnvVariable('TIME_ABORT_REQUEST', process.env.TIME_ABORT_REQUEST),
})

Identity.interceptors.request.use(config => {
  const token = useJwt.getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// toast notifications when error 🧨💣
Identity.interceptors.response.use(
  response => response,
  error => {
    const vm = new Vue()
    if (error.response.data.message) {
      vm.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: error.response.data.message,
        },
      })
    }
    if (error.response.data.messages) {
      error.response.data.messages.forEach(err => {
        vm.$notify({
          type: 'danger',
          verticalAlign: 'top',
          horizontalAlign: 'center',
          message: err,
        })
      })
    }
    return Promise.reject(error)
  },
)

export default Identity
/* eslint-enable no-param-reassign */
