import consoleAxiosInstance from '@/utils/consoleAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getUsers(context, { take = 10, skip = 0, name = null }) {
      return new Promise((resolve, reject) => {
        consoleAxiosInstance
          .get('/users', {
            params: {
              take,
              skip,
              FilterString: name,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
