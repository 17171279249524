import axios from 'axios'
import Vue from 'vue'

import authService from '@/utils/authorize-service'
// eslint-disable-next-line
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueI18n from '@/libs/i18n/index'
// eslint-disable-next-line import/no-cycle
import widgetFunctions from '@/components/widgets/widgetsFunctions'

const extendAxiosInstance = extendedInstance => {
  let isSigningIn = false

  const downloadBlob = ((blob, filename) => {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href) // Clean up the URL object
  })

  function getFileNameFromContentDisposition(contentDisposition) {
    // Example: contentDisposition = "attachment; filename=\"example-file.txt\""
    const regex = /filename=["'](.*?)["']/
    const match = contentDisposition.match(regex)
    if (match && match[1]) {
      return match[1]
    }
    return null // Return null if no filename is found
  }

  function showErrors(errors) {
    for (let i = 0; i < errors.length; i += 1) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: errors[i].toString(),
          variant: 'danger',
        },
      })
    }
  }

  extendedInstance.interceptors.request.use(config => {
    const currentUser = JSON.parse(sessionStorage.getItem('userData'))
    if (currentUser) {
      config.headers.Authorization = `Bearer ${currentUser.access_token}`
    }

    config.headers['Accept-Language'] = VueI18n.locale

    return config
  })

  extendedInstance.interceptors.response.use(
    response => {
      // Show success message on 200 or 201 POST response
      // if ((response.config.method === 'post' || response.config.method === 'put') && (response.status === 200 || response.status === 201)) {
      //   debugger
      //   if (response.data.errorDetails || !response.data.errorDetails || !response.data.errorDetailsBank) {
      //     Vue.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: response.data.message || 'Movimiento exitoso!',
      //         icon: 'CheckIcon',
      //         variant: 'success',
      //       },
      //     })
      //   }
      // }

      // Simulate and force user download on blob response
      if (response.config.responseType === 'blob') {
        const type = response.headers['content-type'] ?? 'pdf'
        const blob = new Blob([response.data], {
          type,
          encoding: 'UTF-8',
        })
        if (type.includes('pdf')) {
          downloadBlob(blob, response.config.filename)
        } else if (type.includes('csv') || type.includes('application/txt')) {
          downloadBlob(blob, response.config.filename)
        } else if (type.includes('application/octet-stream')) {
          downloadBlob(blob, getFileNameFromContentDisposition(response.headers['content-disposition']))
        } else {
          const url = window.URL.createObjectURL(blob)
          const { addWidget } = widgetFunctions()
          addWidget({
            type: 'PdfViewer',
            width: 12,
            data: {
              urlBlob: url,
            },
          })
        }
      }
      return response
    },
    async error => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        Vue.prototype.$toast.error('La espera fue demasiado larga. Su movimiento se ha cancelado.', {
          closeOnClick: true,
          icon: 'XOctagonIcon',
        })
        return Promise.reject(error)
      }
      if (!error.response) {
        authService.logOut('networkError')
        return Promise.reject(error)
      }
      if (error.response.status === 401) {
        const { config } = error
        // TODO: This should perhaps work on the authorize-service
        if (isSigningIn) {
          //
        } else {
          isSigningIn = true
          await authService.signIn()
          isSigningIn = false
          const token = useJwt.getToken()
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
            return axios(config)
          }
        }
      }
      if (error.response.data) {
        if (error.response.request.responseType === 'blob') {
          error.response.data = JSON.parse(await error.response.data.text())
        }
        if (error.response.data.Message || error.response.data.message) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.Message || error.response.data.message,
              variant: 'danger',
            },
          })
        }
        if (error.response.data.errors) {
          const errors = Object.values(error.response.data.errors)
          showErrors(errors)
        } else if (error.response.data.Errors) {
          const errors = Object.values(error.response.data.Errors)
          showErrors(errors)
        }
      }
      return Promise.reject(error)
    },
  )

  return extendedInstance
}

export default extendAxiosInstance
