// eslint-disable-next-line import/no-cycle
import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBeneficiaries(_, { customerCode, bankingArea }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/beneficiaries/get_beneficiaries_by_customer_code', {
            params: {
              customerCode,
              bankingArea,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveBeneficiaries(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/v1/beneficiaries', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    validateSwiftAccount(_, { beneficiaryBankAccount, beneficiaryBankAccountType }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/beneficiaries/validate_aba_swift_account', {
            params: {
              beneficiaryBankAccount,
              beneficiaryBankAccountType,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getBeneficiariesByCustomerCsv(
      _,
      {
        customerCode,
        bankingArea,
        format = 'csv',
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/v1/beneficiaries/get_beneficiaries_by_customer_code.csv', {
            params: {
              customerCode,
              bankingArea,
              format,
            },
            timeout: process.env.TIME_ABORT_REQUEST,
            filename: 'Beneficiarios.csv',
            blob: 'Beneficiarios.csv',
            responseType: 'blob',
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getInstructionsByBeneficiariesList(_, { payload }) {
      const file = new FormData()
      file.append('TransactionNumber', payload.forexTransactionId)
      file.append('PortfolioCode', payload.portfolioCode)
      file.append('CustomerCode', payload.customerCode)
      file.append('CurrencyISOCode', payload.currencyISOCode)
      file.append('ProductCode', payload.productCode)
      file.append('IsCCy', payload.isCCy)
      file.append('BankingArea', payload.bankingArea)
      file.append('File', payload.file)
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/v1/beneficiaries/get_instructions_by_beneficiaries_list', file, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
