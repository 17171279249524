import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    phoneTypes: [],
    emailTypes: [],
    addressTypes: [],
  },
  getters: {
    phoneTypes(state) {
      return state.phoneTypes.length
        ? state.phoneTypes
        : JSON.parse(sessionStorage.getItem('phoneTypes'))
    },
    emailTypes(state) {
      return state.emailTypes.length
        ? state.emailTypes
        : JSON.parse(sessionStorage.getItem('emailTypes'))
    },
    addressTypes(state) {
      return state.addressTypes.length
        ? state.addressTypes
        : JSON.parse(sessionStorage.getItem('addressTypes'))
    },
  },
  mutations: {
    SET_PHONE_TYPES(state, payload) {
      state.phoneTypes = payload
      sessionStorage.setItem('phoneTypes', JSON.stringify(payload))
    },
    SET_EMAIL_TYPES(state, payload) {
      state.emailTypes = payload
      sessionStorage.setItem('emailTypes', JSON.stringify(payload))
    },
    SET_ADDRESS_TYPES(state, payload) {
      state.addressTypes = payload
      sessionStorage.setItem('addressTypes', JSON.stringify(payload))
    },
  },
  actions: {
    fetchPhoneTypes({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (sessionStorage.getItem('phoneTypes')) {
          resolve(getters.phoneTypes)
          return
        }
        sigloNetAxiosInstance
          .get('/phone-types')
          .then(response => {
            commit('SET_PHONE_TYPES', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPhones(_, { payeeId, entityType }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/phones/${payeeId}`, { params: { entityType } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    savePhone(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/phones', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deletePhone(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/phones/delete', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEmailsTypes({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (sessionStorage.getItem('emailTypes')) {
          resolve(getters.emailTypes)
          return
        }
        sigloNetAxiosInstance
          .get('/email-types')
          .then(response => {
            commit('SET_EMAIL_TYPES', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEmails(_, { payeeId, entityType }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/emails/${payeeId}`, { params: { entityType } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveEmail(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/emails', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteEmail(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/emails/delete', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchAddressTypes({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (sessionStorage.getItem('addressTypes')) {
          resolve(getters.addressTypes)
          return
        }
        sigloNetAxiosInstance
          .get('/address-types')
          .then(response => {
            commit('SET_ADDRESS_TYPES', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchZipCodes(_, { StateId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/cities/get_zipcodes_by_state', { params: { StateId } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveAddress(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/address', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteAddress(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/address/delete', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchAddresses(_, { payeeId, entityType }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/address/${payeeId}`, { params: { entityType } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
