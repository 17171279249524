import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    banks: {
      bankAccountTypeCode: {
        display: false,
        value: 0,
      },
      bankName: {
        display: true,
        value: '',
        type: 'input',
        label: 'bankName',
      },
      bankDescription: {
        display: true,
        value: '',
        type: 'input',
        label: 'description',
      },
      bankCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'bankCode',
      },
      banxicoKey: {
        display: false,
        value: 0,
        type: 'numInput',
        label: 'banxicoKey',
      },
      bankSettlementTypeId: {
        display: false,
        value: 0,
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      version: {
        display: false,
        value: '',
        type: '',
      },
      bankAccountSettlementTypeId: {
        display: false,
        value: 0,
      },
      accountTypeCode: {
        display: false,
        value: 0,
      },
      account: {
        display: false,
        value: '',
      },
      isForeignBank: {
        display: false,
        value: false,
      },
      versionAccount: {
        display: false,
        value: '',
      },
      isActiveAccount: {
        display: false,
        value: false,
      },
    },
  },

  getters: {
    banks(state) {
      return state.banks
    },
  },
  mutations: {},
  actions: {
    getCreditDebitBanks() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/banks-settlement-type/get_debit_credit_banks', {
            params: {
              DefaultInstitutionID: 1,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getTransferBanks() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/banks-settlement-type/get_transfer_banks')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getSpeiBanks() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/banks-settlement-type/get_spei_banks')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getSpidBanks() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/banks-settlement-type/get_spid_banks')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getBanks() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/banks-settlement-type/get_banks')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getBankRelations(_, { settlementTypeId, currencyId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/pay_set_bank_def/by_settlement_type_and_currency', {
            params: {
              settlementTypeId,
              currencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getAllBanksInfo(
      _,
      {
        perPage, currentPage, filterByProductCode, orderBy = null,
      },
    ) {
      if (orderBy) {
        orderBy = Object.entries(orderBy).reduce((acc, [key, value]) => {
          acc[key.split('.')[1]] = value
          return acc
        }, {})
      }
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(
            `/banks-settlement-type?FilterByAccountTypeCode=7${Object.entries(orderBy)
              .map(([clave, valor]) => `OrderBy[${clave}]=${valor}`)
              .join('&')}`,
            {
              params: {
                PerPage: perPage,
                CurrentPage: currentPage,
                FilterByProductCode: filterByProductCode,
                OrderBy: orderBy,
              },
            },
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchBanksById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/banks-settlement-type/id?FilterById=${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveBank(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/banks-settlement-type', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateBank(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/banks-settlement-type', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
