import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSpreads(_, CurrencyPairId) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/pricing/get_spreads_by_currencypair', {
            params: {
              CurrencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveProductSpread(_, { product, AllCurrencyPairs }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/save_product_spreads', product, {
            params: {
              AllCurrencyPairs,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    savePortafolioSpread(_, { product, AllCurrencyPairs }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/save_portfolio_spreads', product, {
            params: {
              AllCurrencyPairs,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAmountIntervals(_, CurrencyPairId) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/pricing/get_amount_interval_spreads', {
            params: {
              CurrencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateSpotPrice(_, spotPrice) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/update_spot_price', spotPrice)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    toggleActiveSpotOperation(_, isActive) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/toggle_spot_operation', {}, {
            params: {
              // eslint-disable-next-line
              'IsActive': isActive
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    toggleActivePanicSpread(_, isActive) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/toggle_panic_spread', {}, {
            params: {
              // eslint-disable-next-line
              'IsActive': isActive
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveInterval(_, { interval }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/update_amount_interval_spreads', interval)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getRanges() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/amountintervals')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveRange(_, { range }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/amountintervals', range)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOfficialExchangeRate(_, ValuationDate) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/pricing/get_official_exchange_rate', {
            params: {
              ValuationDate,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveOfficialExchangeRate(_, exchangeRate) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/save_official_exchange_rate', exchangeRate)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getFixExchangeRate(_, ValuationDate) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/pricing/get_fix_exchange_rate', {
            params: {
              ValuationDate,
              CurrencyPairId: 1,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveFixExchangeRate(_, exchangeRate) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/pricing/save_fix_exchange_rate', exchangeRate)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCurrenciesPosition(_, { portfolioId, currencyId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_position/get_currencies_position', {
            params: {
              portfolioId, currencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getFixExchangeRateByDate(_, valuationDate) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('pricing/get_fix_exchange_rate_by_date', {
            params: {
              valuationDate,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
