<template>
  <div
    class="toastification"
    @click="$emit('close-toast')"
  >
    <div class="d-flex align-items-start">
      <b-avatar
        v-if="variant !=='danger'"
        :variant="variant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          :icon="icon"
          size="15"
        />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            v-if="title"
            :class="textClass"
            v-text="title"
          />
          <small
            v-if="text"
            class="d-inline-block text-body"
            v-text="text"
          />
        </div>
        <span
          v-if="variant==='danger'"
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="copy"
        >
          <feather-icon
            size="18"
            color="#fff"
            icon="CopyIcon"
            class="text-body"
          />
        </span>
        <span
          v-else
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <feather-icon
            v-if="!hideClose"
            icon="XIcon"
            class="text-body"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    function copy() {
      navigator.clipboard.writeText(props.title)
    }

    const textClass = computed(() => {
      if (props.variant === 'danger') {
        return `text-${props.variant} mb-0 toastification-title`
      }
      return `text-${props.variant} mb-0 font-weight-bolder toastification-title`
    })

    return {
      copy,
      textClass,
    }
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
