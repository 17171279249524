<template>
  <div
    class="btn-scroll-to-top"
  >
    <div>
      <b-button
        id="show-btn"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.modal-center
        variant="primary"
        class="btn-icon rounded-circle"
        size="lg"
        @click="$bvModal.show('dv-modal-menu')"
      >
        <feather-icon
          icon="PlusIcon"
          size="20"
        />
      </b-button>
    </div>

    <b-modal
      id="dv-modal-menu"
      class="dv-modal-menu"
      hide-footer
      hide-header
      centered
    >
      <div>
        <b-row>
          <b-col />
          <b-col
            cols="8"
            class="d-flex justify-content-center"
          >
            <span class="h4 text-white"> {{ $t('menu') }} </span>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <feather-icon
              icon="XIcon"
              size="30"
              class="cursor-pointer"
              @click="$bvModal.hide('dv-modal-menu')"
            />
          </b-col>
        </b-row>
        <b-container>
          <b-row
            class="d-flex justify-content-between"
          >
            <div
              v-for="(button, n) in uiButtons"
              :key="n"
              class="d-flex"
              style="width: 150px ;max-width: 150px;"
            >
              <b-button
                class="d-flex flex-column align-items-center customer-boards-buttons mt-1 w-100"
                :variant="'uiButton'"
                :disabled="false"
                @click="openWidget(button.widgetProperties); $bvModal.hide('dv-modal-menu')"
              >
                <b-img
                  v-if="button.mobilBtnImg"
                  width="80px"
                  :src="
                    require(`@/assets/images/icons/board-icons/${button.mobilBtnImg}`)
                  "
                />
                <span>
                  {{ $t(button.name) }}
                </span>
              </b-button>
            </div>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      id="dv-modal-boards"
      hide-header
      centered
    >
      <b-row>
        <b-col />
        <b-col
          cols="8"
          class="d-flex justify-content-center"
        >
          <span class="h4 text-white"> {{ $t('navigationMap') }} </span>
        </b-col>
        <b-col>
          <feather-icon
            icon="XIcon"
            size="30"
            class="cursor-pointer"
            @click="$bvModal.hide('dv-modal-boards')"
          />
        </b-col>
      </b-row>
      <div>
        <div
          v-for="(board, index) in boards"
          :key="index"
          class="d-flex justify-content-between align-items-center pt-1"
        >
          <swipe-button
            v-if="board.widgets[0]"
            class="w-100"
            :name="getWidgetName(`${board.widgets[0].id}-${board.widgets[0].uniqueId}`)"
            @clickEvent="chooseBoard(index); $bvModal.hide('dv-modal-boards')"
            @swipeEvent="deleteBoard(index)"
          />
        </div>
      </div>
      <template #modal-footer>
        <b-button
          v-show="boards.length > 0"
          size="sm"
          pill
          style="height: 40px; font-size: 1rem;"
          variant="outline-danger"
          class="w-100"
          @click="closeAllWidgets"
        >
          {{ $t('closeWidgets') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import {
  computed,
  watch,
  getCurrentInstance,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import widgetFunctions from '@/components/widgets/widgetsFunctions'
import swipeButton from '@/components/helpers/swipeButton.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    swipeButton,
  },
  setup(_, { root }) {
    const { addWidget } = widgetFunctions()
    const store = root.$store

    const boards = computed(() => store.state.boards.boards)
    const swipeDirection = computed(() => store.state.app.swipeDirection)
    const uiButtons = computed(() => store.getters['app/filterMobilMenuButtons'])

    const openWidget = operation => {
      addWidget(operation)
    }

    const deleteBoard = index => {
      store.commit('boards/REMOVE_BOARD', index)
    }

    const chooseBoard = index => {
      store.commit('boards/SELECT_BOARD', index)
    }

    const { proxy } = getCurrentInstance()

    watch(swipeDirection, val => {
      if (val === 'up') {
        proxy.$bvModal.show('dv-modal-boards')
      }
    })

    const getWidgetName = widgetId => {
      const widget = document.getElementById(widgetId)

      if (widget) {
        const widgetTitle = widget.querySelector('span.h4')
        const widgetName = widget.querySelector('span.h5')
        if (widgetTitle && widgetName) {
          return `${widgetTitle.textContent} ${widgetName.textContent}`
        }
        return widgetTitle.textContent
      }
      return null
    }

    const closeAllWidgets = () => {
      store.commit('boards/REMOVE_ALL_WIDGET')
    }

    return {
      openWidget,
      boards,
      deleteBoard,
      chooseBoard,
      uiButtons,
      getWidgetName,
      closeAllWidgets,
    }
  },

}
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
    bottom: 6%;
    right: 10px;
    z-index: 99;
}

.dark-layout .bg-custom-dark span {
  color: #f88424 !important;
}
</style>
