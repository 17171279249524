import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    riskLevels: [],
  },
  getters: {
    riskLevels(state) {
      return state.riskLevels.length
        ? state.riskLevels
        : JSON.parse(sessionStorage.getItem('riskLevels'))
    },
  },
  mutations: {
    SET_RISK_LEVELS(state, payload) {
      state.riskLevels = payload
      sessionStorage.setItem('riskLevels', JSON.stringify(payload))
    },
  },
  actions: {
    fetchComplianceInfo(context, { payeeId, complianceType, contractId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/accomplishment/get_compliance_information', {
            params: {
              ParentID: payeeId,
              ComplianceType: complianceType,
              ContractId: contractId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchRelatedPersonsTypes() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/related_person_types')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchDocumentTypes() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/document_types')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveDocument(_, payload) {
      const file = new FormData()
      file.append('EntityId', payload.value.entityId)
      file.append('ComplianceType', payload.value.complianceType)
      file.append('Document.DocumentFile', payload.value.document.file)
      file.append('Document.DocumentName', payload.value.document.file.name)
      file.append('Document.DocumentFileName', payload.value.document.file.name)
      file.append('Document.DocumentFileExtension', payload.value.document.file.name.split('.').pop())
      file.append('Document.DocumentTypeId', payload.value.document.documentTypeId)
      file.append('Document.Issuer', payload.value.document.issuer)
      file.append('Document.DocumentNumber', payload.value.document.documentNumber)
      file.append('Document.IssueDate', payload.value.document.issueDate)
      file.append('Document.ExpirationDate', payload.value.document.expirationDate)
      file.append('Document.IsOriginal', payload.value.document.isOriginal)
      file.append('Document.DocumentId', payload.value.document.documentId)
      file.append('Document.DocumentFileId', payload.value.document.documentFileId)
      file.append('Document.Version', payload.value.document.version)
      file.append('RelationshipId', payload.value.relationshipId)
      file.append('Version', payload.value.version)
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/accomplishment/add_document', file, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchDocument(_, documentId) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/accomplishment/get_document_by_id', {
            params: {
              DocumentId: documentId,
              ContractId: 0,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    downloadDocument(_, id) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/accomplishment/download_document', {
            params: {
              DocumentId: id,
            },
            responseType: 'blob',
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteDocument(_, { payload, ComplianceType }) {
      delete payload.document.documentFile
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/accomplishment/delete_document', payload, {
            params: {
              ComplianceType,
            },
          })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchRiskLevels({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (sessionStorage.getItem('riskLevels')) {
          resolve(getters.riskLevels)
          return
        }
        sigloNetAxiosInstance
          .get('/risk_level_types')
          .then(response => {
            commit('SET_RISK_LEVELS', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveBlackList(_, payload) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/black_list', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
