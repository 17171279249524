import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    webParams: {
      webPriceId: {
        display: false,
        value: 0,
        type: 'input',
        label: 'webPrice',
      },
      version: {
        display: false,
        value: '',
        type: '',
      },
    },
  },

  getters: {
    webParams(state) {
      return state.webParams
    },
  },
  mutations: {},
  actions: {
    getWebModule() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/web_module')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPortfolios() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/portfolios?FilterByIsActive=true')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateProprety(_, data) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/web_module', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
