import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// Widgets
import './data/widgets/widgets'

// Users
import './data/apps/user'
import './data/apps/profile-data'
import './data/apps/account-setting'

/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
