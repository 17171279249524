import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getExchangeRate() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/exchange_rate_opening_closing')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    createExchangeRate(_, exchangeRate) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/exchange_rate_opening_closing', exchangeRate)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
