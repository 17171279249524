import { $themeBreakpoints } from '@themeConfig'

const filterMobileAvailableOperations = obj => {
  const result = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (obj[key].operations) {
      const mobileOperations = obj[key].operations.filter(operation => operation.isMobileAvailable)
      if (mobileOperations.length > 0) {
        result[key] = { ...obj[key], operations: mobileOperations }
      }
    }
  }
  return result
}

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    menuButtons: [],
    swipeDirection: '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },

    // retuns true only if windowWidth is sm or xs
    movileBreakpoint(state) {
      const { windowWidth } = state
      if (windowWidth <= $themeBreakpoints.sm) {
        return true
      }
      return false
    },

    filterMobilMenuButtons(state) {
      const operations = []
      const data = filterMobileAvailableOperations(state.menuButtons)
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        if (data[key].operations) {
          operations.push(...data[key].operations)
        }
      }
      return operations
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_MENU_BUTTONS(state, val) {
      state.menuButtons = val
    },
    SET_SWIPE_DIRECTION(state, val) {
      state.swipeDirection = val
    },
  },
  actions: {},
}
